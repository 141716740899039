﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NiComponentsModule } from '../ni-components/ni-components.module';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { TreeTableModule } from 'primeng/treetable';
import { OrganizationChartModule } from 'primeng/organizationChart';
import { PageHomeComponent } from '../pages/home/home.component';
import { PageLoginComponent } from '../pages/login/login.component';


import
{
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { CustomComponentsModule } from "../custom-components/custom-components.module";
import { PipesModule } from "../pipes/pipes.module";
import { NgSelectModule } from '@ng-select/ng-select';
//import { PageAwarenessComponent } from './awareness/awareness.component';
//import { PageFunnelComponent } from '../pages/funnel/funnel.component';
//import { PageCoolerComponent } from '../pages/Cooler/cooler.component';
//import { PageCokeComponent } from '../pages/Coke/coke.component';
//import { PageFantaComponent } from '../pages/Fanta/fanta.component';
//import { PageSpriteComponent } from '../pages/Sprite/sprite.component';
//import { PageKinleyComponent } from '../pages/Kinley/kinley.component';
//import { PageActivationComponent } from '../pages/Activation/activation.component';
import { PageTotalComponent } from '../pages/Total/total.component';
//import { PageOverallComponent } from '../pages/overall/overall.component';
import { PageCategoryComponent } from '../pages/Category/category.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NiComponentsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        CustomComponentsModule,
        PipesModule,
        NgSelectModule,
        TableModule,
        PanelModule,
        TreeTableModule,
        DialogModule,
        OrganizationChartModule
    ],
    declarations: [
        PageLoginComponent,
        PageHomeComponent,
        PageTotalComponent,
        PageCategoryComponent
    ],
    exports: [
        PageLoginComponent,
        PageHomeComponent,
        PageTotalComponent,
        PageCategoryComponent

    ],
    entryComponents: [
    ]
})
export class PagesModule { }
