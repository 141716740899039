import { JwtHelperService  } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../providers/constants';
import { StorageService } from '../providers/storage.service';
import { MasterService } from '../providers/master.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable()
export class AuthService
{
    jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(private masterService: MasterService, private http: HttpClient)
    {
    }

    public isLoggedin()
    {
        var user = StorageService.get('user');
        if (!user || !user.access_token)
            return false;

        return !this.jwtHelper.isTokenExpired(user.access_token);
    }

    static getUser(): any
    {
        return StorageService.get('user');
    }

    setUser(data): any
    {
        return this.masterService.setUser(data);
    }

    login(userName: string, password: string): Observable<boolean>
    {
        let data = { userName: userName, password: password }

        return this.masterService.postData('user/login', data, true).pipe(
            map(response =>
            {
                var user = this.setUser(response);
                return user;
            }));
    }

    logout(): void
    {
        StorageService.remove('user');
    }
}
