import { Injectable } from '@angular/core';
import { Observable, Subject, concat } from 'rxjs';
import { GenericService } from '../providers/generic.service';
import { retry } from 'rxjs/operators';
import { validateConfig } from '@angular/router/src/config';
import { BRANDS } from 'settings/brand';
import { debug } from 'util';
import { Filters } from '../../settings/filters';
import { MasterService } from './master.service';

@Injectable()
export class GlobalService
{
    static path: string;
    static subscription: any;
    static ajaxSubscription: any = [];
    static masterService: MasterService;
    //abc: any = "";
    //def: any = "";

    //private static _defaultFilters = Filters;

    private static _defaultFilters: any[] =
        [
            { Id: "MallName", Clearable: true, Title: "Mall", QID: "Q2", Change: 'onMallChange' },
            { Id: "Brand", Clearable: true, Title: "Brand", QID: "AI_1_Q3", Change: 'onBrandChange' },
            { Id: "Category", Clearable: true, Title: "Category", QID: "category", Change: 'onCategoryChange' }
        ];

    static selectedFilters: string;

    static ajaxSubscriptionsMap = {};

    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();

    static onMallChange()
    {
        let brands = this.filters.filter(x => x.Id == "MallName")[0].Selected;
        let filters = "";
        if (brands && brands.length > 0)
            filters = " and Q2 in (" + brands.join(",") + ")";
        this.masterService.callGetService("nameValue/filter-list?variables=AI_1_Q3,category&groupNames=brand,category&filters=" + filters, true)
            .subscribe(
                (data) =>
                {
                    var filters = this.filters.filter(x => x.Id != "MallName")
                    for (let f of filters)
                    {
                        f.List = data.filter(x => x.Group == f.Id) || [];
                        f.Selected = [];
                    }
                });
    }
    static onCategoryChange()
    {
        let brands = this.filters.filter(x => x.Id == "Category")[0].Selected;
        let filters = "";
        if (brands && brands.length > 0)
            filters = " and Category in (" + brands.join(",") + ")";
        this.masterService.callGetService("nameValue/filter-list?variables=AI_1_Q3,Q2&groupNames=brand,mallname&filters=" + filters, true)
            .subscribe(
                (data) =>
                {
                    var filters = this.filters.filter(x => x.Id != "Category")
                    for (let f of filters)
                    {
                        f.List = data.filter(x => x.Group == f.Id) || [];
                        f.Selected =  [];
                    }
                });
    }
    static onBrandChange()
    {      
        let brands = this.filters.filter(x => x.Id == "Brand")[0].Selected;
        let filters = "";
        if (brands && brands.length > 0)
            filters = " and AI_1_Q3 in (" + brands.join(",") + ")";
        this.masterService.callGetService("nameValue/filter-list?variables=category,Q2&groupNames=category,mallname&filters=" + filters, true)
            .subscribe(
                (data) =>
                {
                    var filters = this.filters.filter(x => x.Id != "Brand")
                    for (let f of filters)
                    {
                        f.List = data.filter(x => x.Group == f.Id) || [];
                        f.Selected = [];
                    }
                });
    }

    static changeTitle(newTitle: string)
    {
        let that = this;
        setTimeout(function ()
        {
            that._titleObserver.next(newTitle);
            that.routeChange();
        }, 1000);

    }

    private static _filters: any[];
    static get filters()
    {
        if (!this._filters || this._filters.length == 0)
            this._filters = GenericService.clone(this._defaultFilters);
        return this._filters;
    }

    static set filters(filters)
    {
        this._filters = filters;
        this.emitFilterChanges();
    }

    private static _filtersObserver = new Subject();
    static filtersObservable = GlobalService._filtersObserver.asObservable();
    static emitFilterChanges(createClone = false)
    {
        if (createClone)
            this._filters = GenericService.clone(this.filters);
        this._filtersObserver.next(this.filters);
    }

    static areFiltersLoaded()
    {
        return this.filters && this.filters.length > 0 && this.filters[0].List && this.filters[0].List.length > 0;
    }

    static loadFilters(masterService)
    {
        this.masterService = masterService;
        let filterIds = this._defaultFilters.map(x => x.Id);

        masterService.callService("nameValue/list?variables=" + filterIds, true)
            .subscribe(
                (data) =>
                {
                    //let abc = data.name;
                    //let def = data.value;
                    this.path = GenericService.getCurrentPath();
                    for (let f of this.filters)
                    {

                        f.List = data.filter(x => x.Group == f.Id) || [];
                        f.Selected = f.Selected || [];
                        f.Multiple = f.Multiple == undefined ? true : f.Multiple;
                        f.Visible = f.Visible == undefined ? true : f.Visible;
                        f.EnableSearch = false;
                    }
                    this.emitFilterChanges();
                });
    }

    //static serializeFilters(filters = null, excludedFilters = null)
    //{
    //    var obj = this.getMongoFiltersObj(filters, excludedFilters);
    //    if (!obj)
    //        return "";

    //    let str = JSON.stringify(obj);
    //    return str;
    //}


    static serializeFilters(filters = null, excludeFilters = null)
    {
        this.getSelectedFilters();
        var strFilters = "";
        for (let f of this.filters)
        {
            if (f.List)
            {
                var selectedList = f.Selected ? f.Selected : [];
                if (selectedList.length > 0)
                {
                    strFilters += ";" + f.QID + ":" + GenericService.toCsv(selectedList);
                }
            }
        }
        return strFilters;
    }

    static showHideFilters()
    {
        GlobalService.path = GenericService.getCurrentPath();
        for (let f of this.filters)
        {
            f.Visible = true;
            f.Disabled = false;

            //if (f.Id == "Rolling")
            //{
            //    if (GlobalService.path == '/brand-pyramid/BC1' || GlobalService.path == '/equity-pyramid')
            //    {
            //        f.Visible = false;
            //    }
            //    else
            //    {
            //        f.Visible = true;
            //    }
            //}

            //if (f.Id == "Brand" && GlobalService.path != '/awareness/0' && GlobalService.path != '/awareness/1' && GlobalService.path != '/snapshot' && GlobalService.path != '/brand-pyramid/BC1' && GlobalService.path != '/equity-pyramid' && GlobalService.path != '/imagery-table/BL3_J' && GlobalService.path != '/imagery-table/BL3_M')
            //    f.Visible = false;

            if (GlobalService.path == '/imagery-table/BL3_M' || GlobalService.path == '/imagery/BL3_M/0' || GlobalService.path == '/imagery/BL3_M/1')
            {
                if (f.Id == "Category")
                {
                    f.Selected = ["Malts"];
                    f.Disabled = true;
                }
                else if (f.Id == "Brand")
                {
                    f.Disabled = true;
                }
            }
            else if (GlobalService.path == '/imagery-table/BL3_J' || GlobalService.path == '/imagery/BL3_J/0' || GlobalService.path == '/imagery/BL3_J/1')
            {
                if (f.Id == "Category")
                {
                    f.Selected = ["Juice"];
                    f.Disabled = true;
                }
                else if (f.Id == "Brand")
                {
                    f.Disabled = true;
                }
            }
            if (f.Id == "Brand Type" || f.Id == "Category" || f.Id == "Brand")
                f.Visible = false;

        }
    }

    static resetFilters()
    {
        this.path = GenericService.getCurrentPath();
        for (let f of this.filters)
        {
            if (f.Visible)
            {
                let df = this._defaultFilters.find(x => x.Id == f.Id);
                f.Selected = df.Selected ? GenericService.clone(df.Selected) : null;
            }
        }
    }

    static getMongoFiltersObj(filters = null, excludedFilters = null)
    {
        this.getSelectedFilters();
        filters = filters || this.filters;
        //filters = filters.filter(x => x.Id != "Category" && x.Id != "Brand Type");
        var obj = { "$and": [] };
        for (let f of filters)
        {
            if (f.Selected && f.Visible != false)
            {
                let temp = {};
                if (excludedFilters && excludedFilters.find(x => x == f.Id))
                    continue;
                else if (f.Id == 'Rolling' && this.toNumber(f.Selected) == 1)
                    continue;
                if (f.Multiple == false)
                    temp[f.Id] = this.toNumber(f.Selected);
                else
                {
                    if (f.Selected.length > 1)
                        temp[f.Id] = { $in: this.toNumber(f.Selected) };
                    else
                        temp[f.Id] = this.toNumber(f.Selected[0]);
                }
                obj["$and"].push(temp);
            }
        }

        if (obj["$and"].length == 0)
            return null;

        return obj;
    }

    static addToMongoFilters(mongoFilters, filtersToAdd)
    {
        if (!mongoFilters)
            mongoFilters = { "$and": [] };

        mongoFilters["$and"] = mongoFilters["$and"].concat(filtersToAdd);

        return mongoFilters;
    }

    static removeFromMongoFilters(mongoFilters, filterNames)
    {
        if (!mongoFilters)
            return mongoFilters;

        filterNames = Array.isArray(filterNames) ? filterNames : [filterNames];
        mongoFilters["$and"] = mongoFilters["$and"].filter(x => filterNames.indexOf(Object.keys(x)[0]) == -1);

        if (mongoFilters["$and"].length == 0)
            return null;

        return mongoFilters;
    }

    static getFilterValue(mongoFilters, filterName)
    {
        if (!mongoFilters)
            return null;

        let obj = mongoFilters["$and"].find(x => Object.keys(x)[0] == filterName)
        let value = obj ? obj[filterName] : null;
        if (value && typeof value === 'object' && value.hasOwnProperty('$in'))
            return value['$in'];
        return value;
    }


    static toNumber(arrOrValue)
    {
        if (Array.isArray(arrOrValue))
            return arrOrValue.map(x => !isNaN(x) ? parseFloat(x) : x);
        else
            return !isNaN(arrOrValue) ? parseFloat(arrOrValue) : arrOrValue;
    }

    static getSelectedFilters(filters = null)
    {
        filters = filters || this.filters;
        var strFilters = "";
        for (let f of filters)
        {
            if (f.List && f.List.length > 0 && f.Selected)
            {
                if (f.Multiple != false)
                {
                    if (f.Selected.length > 0)
                    {
                        let selected = f.Selected;// f.List.filter(x => f.Selected.find(y => y == x.Id));
                        let tempStr = "";
                        selected.forEach(x =>
                        {
                            tempStr += "," + this.filters.filter(y => y.Id == f.Id)[0].List.filter(y => y.Value == x)[0].Name;
                        });
                        tempStr = tempStr.substring(1);
                        strFilters += " <b>" + f.Title + ":</b> " + tempStr;  // GenericService.toCsv(selected);
                    }
                }
                else
                {
                    let val = f.List.find(x => f.Selected == x.Id);
                    strFilters += " | <b>" + f.Title + ":</b> " + val.Title;
                }
            }
        }

        this.selectedFilters = strFilters

        return strFilters;
    }

    static reloadFilter(masterService, filterId, serviceName, filters = null)
    {
        if (this.ajaxSubscriptionsMap[filterId])
            this.ajaxSubscriptionsMap[filterId].unsubscribe();

        filters = filters || this.filters;
        let filter = filters.find(x => x.Id == filterId);
        var url = serviceName;
        var filterStr = this.serializeFilters(null, filters);
        url = GenericService.addToQueryString(url, 'filters', filterStr);

        this.ajaxSubscriptionsMap[filterId] = masterService.callService(url, true)
            .subscribe(
                (list) =>
                {
                    filter.List = list;
                    if (filter.List && filter.List.length > 0 && filter.Selected && filter.Selected.length > 0)
                    {
                        if (filter.Multiple == false)
                        {
                            let selected = list.find(x => x.Code == filter.Selected);
                            if (!selected)
                                filter.Selected = null;
                        }
                        else
                        {
                            filter.Selected = filter.Selected.filter(x => list.find(y => y.Id == x));
                        }
                    }
                });
    }

    static buildVariables(varPrefix, selectedBrandCodes = null, selectedStatementCodes = null)
    {
        let variables = [];
        selectedBrandCodes = Array.isArray(selectedBrandCodes) ? selectedBrandCodes : [selectedBrandCodes];
        selectedStatementCodes = Array.isArray(selectedStatementCodes) ? selectedStatementCodes : [selectedStatementCodes];

        selectedBrandCodes.forEach(brandCode =>
        {
            if (varPrefix == "BIL" || varPrefix == "FSLM")
                variables.push("T3B_" + varPrefix + "_" + brandCode);

            else if (varPrefix == "BA1" || varPrefix == "Total_Spontaneous" || varPrefix == "Total_Awareness" || varPrefix == "BC1"
                || varPrefix == "BC2" || varPrefix == "BC3" || varPrefix == "BC4" || varPrefix == "Favourite")
                variables.push(varPrefix + "_ERA_" + brandCode);

            else if (varPrefix == "BC2_1" || varPrefix == "BC5" || varPrefix == "BC6")
                variables.push(varPrefix + "_" + brandCode + "_SC");
            else if (varPrefix == "BVC1")
            {
                selectedStatementCodes.forEach(statCode =>
                {
                    variables.push(varPrefix + "_" + statCode + "_SC_ERA_" + brandCode);
                });
            }
            else
            {
                selectedStatementCodes.forEach(statCode =>
                {
                    if (varPrefix == "BL1")
                        variables.push("BL1_" + brandCode + "_SC_" + statCode + "_SC2");
                    else if (varPrefix == "BL2")
                        variables.push(varPrefix + "_" + statCode + "_" + brandCode);
                    else if (varPrefix == "BL3_J" || varPrefix == "BL3_M")
                        variables.push(varPrefix + "_" + statCode + "_" + brandCode);

                });
            }
        });

        return variables;
    }

    static getCodes(variable)
    {
        let res: any = {};
        let arr = variable.split("_");

        if (variable.startsWith("BL1"))
        {
            res.BrandCode = arr[1].trim();
            res.StatementCode = arr[3].trim();
        }
        else if (variable.startsWith("BL2"))
        {
            res.BrandCode = arr[2].trim();
            res.StatementCode = arr[1].trim();
        }
        else if (variable.startsWith("BL3_J") || variable.startsWith("BL3_M"))
        {
            res.BrandCode = arr[3].trim();
            res.StatementCode = arr[2].trim();
        }

        else if (variable.startsWith("BA1") || variable.startsWith("BA2") || variable.startsWith("BA3") || variable.startsWith("BC1")
            || variable.startsWith("BC2") || variable.startsWith("BC3") || variable.startsWith("BC4") || variable.startsWith("BC2_1"))
        {
            res.BrandCode = arr[2].trim();
        }

        return res;
    }

    static routeChange()
    {
        this.path = GenericService.getCurrentPath();
        this.showHideFilters();
    }

    static unsubscribeAjax()
    {
        for (let i = 0; i < this.ajaxSubscription.length; i++)
        {
            if (this.ajaxSubscription[i])
                this.ajaxSubscription[i].unsubscribe();
        }
        this.ajaxSubscription = [];
    }

    static unsubscribe()
    {
        if (this.subscription)
            this.subscription.unsubscribe();

        this.unsubscribeAjax();
    }

    //static brandColors =
    //    {
    //    }

    static keyColors =
        {
            "Wave1": "#8AB9E1"
        }

    

    static getBrandCodes(fltrObj, showDefaultIfNoSelection = false)
    {
        let brandCodes = GlobalService.getFilterValue(fltrObj, 'Brand');
        brandCodes = brandCodes || [];
        brandCodes = Array.isArray(brandCodes) ? brandCodes : [brandCodes];

        if (brandCodes.length == 0)
        {
            brandCodes = GlobalService.filters.find(x => x.Id == "Brand")["List"].map(x => x.Id);
            if (showDefaultIfNoSelection)
            {
                var temp = brandCodes.filter(code => BRANDS.find(x => x.Code == code && (x.Default || x.Default2)));
                if (temp.length > 0)
                    brandCodes = brandCodes.filter(code => BRANDS.find(x => x.Code == code && (x.Default || x.Default2)));
            }
        }
        return brandCodes;
    }

    static onRollingChange()
    {
        let rollingFilter = GlobalService.filters.find(x => x.Id == "Rolling");
        let waveFilter = GlobalService.filters.find(x => x.Id == "Q_Wave");
        // waveFilter.Visible = !rollingFilter.Selected
    }

    static getRolling()
    {
        let rollingFilter = GlobalService.filters.find(x => x.Id == "Rolling");
        return rollingFilter.Selected;
    }

    static brandColors =
        {
            "Dubai Mall": "#A5E348",
            "City Mall (1/2)": "#CAC9D0",
            "Ibn Battuta": "#63C08A",
            "Dragon Mall (1/2)": "#519525",
            "Burjanman": "#37A7B6",
            "Mall of Emirates": "#229DCE",
            "AI Gjurair": "#145E7C",
            "Dubai Festival City": "#A5E348",
            "DCC": "#327C51",
            "AI Seef": "#679C18",
            "La Mer": "#327C51"
        }

    static brandCategoryColors =
        {
            "Apparel": "#589bD5",
            "Departments": "#10426f",
            "Travel Accessories": "#EEB500",
            "Jewellery": "#CAC9D0",
            "Burjanman": "#A5E348",
            "Footwear": "#63C08A",
            "Toys": "#519525",
            "Accessories": "#A5E348",
            "Electronics": "#327C51",
            "Home Furnishings": "#679C18",
            "Watches": "#C0504D",
            "Beauty & perfumes": "#F79646",
            "Eyewear": "#71B2C9"
        }
}

