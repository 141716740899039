import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { GlobalService } from 'app/providers/global.service';
import { filter } from 'rxjs/operators';
import { MasterService } from 'app/providers/master.service';
import { GenericService } from 'app/providers/generic.service';

@Component({
    selector: 'addition-navbar',
    templateUrl: './addition-navbar.component.html',
    styleUrls: ['./addition-navbar.component.scss'],
    host: {
        '[class.addition-navbar]': 'true',
        '[class.open]': 'open'
    }
})
export class AdditionNavbarComponent
{
    open: boolean;
    subscription: any;
    gs = GlobalService;
    @ViewChild('navBar') navBar: ElementRef;

    constructor(private _elRef: ElementRef, private router: Router, private masterService: MasterService)
    {
        this.open = false;

        this.subscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)).subscribe((event: any) =>
            {
                GlobalService.path = event.url.split(";")[0];
            });
    }

    toggleNavbar(event = null)
    {
        if (event)
            event.preventDefault();
        this.open = !this.open;
    }

    filterChange(filter)
    {
      if (filter.Change && GlobalService[filter.Change])
        GlobalService[filter.Change](this.masterService);
    }

    applyFilters()
    {
      GlobalService.emitFilterChanges();
      this.open = false;
    }

    resetFilters()
    {
      GlobalService.resetFilters();
      this.open = false;
    }

    onClickedOutside(navBar, event)
    {
        let clickedByFilterButton = event.target.name == 'filter-button' || (event.target.parentElement && event.target.parentElement.name == 'filter-button');
        if (clickedByFilterButton)
            this.open = true;
        else
        {
            if (!event.target.classList.contains('ng-option'))
                this.open = false;
        }
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
