﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default/default.component';
import { ExtraLayoutComponent } from './layouts/extra/extra.component';
import { environment } from '../environments/environment';

import { PageHomeComponent } from './pages/home/home.component';
import { PageLoginComponent } from '../app/pages/login/login.component';

import { AuthGuardService } from './providers/auth-guard.service';
import { PageTotalComponent } from '../app/pages/Total/total.component';
import { PageCategoryComponent } from '../app/pages/Category/category.component';


const defaultRoutes: Routes =
    [
        { path: 'snapshot', component: PageHomeComponent, canActivate: [AuthGuardService] },

        {
            path: 'total', component: PageTotalComponent, canActivate: [AuthGuardService]
        },
        {
            path: 'category', component: PageCategoryComponent, canActivate: [AuthGuardService]
        }


    ];

const extraRoutes: Routes = [
    { path: 'login', component: PageLoginComponent }
];

export const routes: Routes =
    [
        {
            path: '',
            redirectTo: environment.defaultRoute,
            pathMatch: 'full'
        },
        {
            path: '',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        },
        {
            path: '',
            component: ExtraLayoutComponent,
            children: extraRoutes
        },
        {
            path: '**',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        }
    ];

@NgModule({
    imports: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
