import { Component } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { MasterService } from '../../providers/master.service';
import { finalize } from 'rxjs/operators';
import { FusionChartsService } from 'app/providers/fusion-charts.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'app/providers/data.service';
import { BRANDS } from 'settings/brand';
import { CATEGORIES } from 'settings/category';
import { QUESTIONS } from 'settings/question';
import { ChartService } from '../../providers/chart.service';

@Component({
    moduleId: module.id,
    selector: 'category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})

export class PageCategoryComponent
{
    showBase = true;
    title: string;
    gs = GlobalService;
    varPrefix;
    loading = false;
    indexDrill: any = 0;
    indexDrillname: any = "";
    objvalue: any = "";
    mode = "Category";
    config: any = {};
    objtitle: any = "";
    data: any = "";
    data1: "";


    static mode = "Category";
    static indexDrill: any = 0;


    configs: any = [
        { id: '11', indexDrill: '0', title: 'Overall Category Score', class: 'col-md-6', height: '50vh', type: 'mscolumn2d', service: 'average?charttype=Category', chartOptions: { showLegend: 0 }, typeFlag: 'All', showTrendLines: true, trendLineDisplayValue: 'Performance Avg: 66%' },
        { id: '22', indexDrill: '1', title: 'Overall Score', class: 'col-md-6', height: '50vh', type: 'msbar2d', service: 'perc-multi-resp-table?json=overall.json&charttype=Category', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '33', indexDrill: '2', title: 'Store assortment/availability', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=StoreAssortment.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '44', indexDrill: '3', title: 'Store/mall layout', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=MallLayout.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '55', indexDrill: '4', title: 'Window Display', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=WindowDisplay.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '66', indexDrill: '5', title: 'Innovation at Retail store', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=RetailStore.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '77', indexDrill: '6', title: 'Promotions', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=Promotion.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '88', indexDrill: '7', title: 'Customer Service', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=CustomerService.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
        { id: '99', indexDrill: '8', title: 'Checkout experience', class: 'col-md-6', height: '50vh', type: 'msbar2d', color: '#FF9E19', service: 'perc-multi-resp-table_c3?json=CheckoutExperience.json', chartOptions: { showLegend: 0 }, typeFlag: 'All' },

    ]

    constructor(private route: ActivatedRoute, private masterService: MasterService)
    {
        this.run();
    }

    async ngOnInit()
    {

        this.gs.subscription = FusionChartsService.clickObservable.subscribe((obj: any) =>
        {
            if (obj.chartType == "Category")
            {
                let filterlist = GlobalService.filters;
                this.objvalue = filterlist.filter(x => x.Id == "Category")[0].List.filter(x => x.Name == obj.data)[0].Value;
                obj.chartType = "Overall";
                this.mode = "Overall";
                this.indexDrill = 1;
                this.data = obj.data;
                //this.objvalue = obj.data;
                this.run();
            }
            else if (obj.chartType == "Overall")
            {
                obj.chartType = "Drill";
                this.mode = "Drill";
                this.indexDrill = obj.data + 2;
                this.objtitle = obj.title;
                this.data1 = obj.title;
                //this.indexDrillname = this.configs[this.indexDrill].title;
                this.run();
            }

        });


        GlobalService.filtersObservable.subscribe(filters =>
        {
            this.run();
        });

    }

    reloadHome()
    {
        if (this.mode = "Overall")
        {
            this.mode = "Category",
                this.indexDrill = 0;
            this.data = '';
            this.run();
        }
    }

    static Home()
    {
        if (this.mode = "Overall")
        {
            this.mode = "Category",
                this.indexDrill = 0;

        }
    }

    reloadOverall()
    {

        if (this.mode = "Drill")
        {
            this.mode = "Overall",
                this.indexDrill = 1;
            this.run();
        }

    }

    run()
    {
        let filters = GlobalService.serializeFilters();
        //var config = this.configs[this.indexDrill];
        var config = this.configs.filter(x => (this.mode == "Category" || this.mode == "Overall" ? (parseInt(x.indexDrill) == this.indexDrill) : (x.title == this.objtitle)))[0];
        this.indexDrill = config.indexDrill;
        let service = config.service;
        let url;


        if (this.mode == "Overall")
        {
            filters += ";Category:" + this.objvalue;
        }
        else if (this.mode == "Drill")
        {
            filters += ";Category:" + this.objvalue;
            //filters += ";Q2:" + this.indexDrill ;
            //url = GenericService.addToQueryString(url, "Q2", this.mallName);
            //url = GenericService.addToQueryString(url, "Q2", this.indexDrillname);
        }

        url = GenericService.addToQueryString(service, "filter", filters);

        config.loading = true;
        let sub = this.masterService.callGetService(url).pipe(
            finalize(() =>
            {
                config.loading = false;
                //this.loading = false;
            }))
            .subscribe(res =>
            {
                // config.table.Banners.forEach((x, i) => { x.Color = this.gs.keyColors[x.Name]; x.Name = GenericService.toTitleCase(x.Name); });

                res.Stubs.forEach((x, j) =>
                {
                    // x.Color = ChartService.colors[i];
                    x.Color = this.gs.brandCategoryColors[x.Name];
                });
                //GenericService.getTop10DataWithDefaultBrands(res, this.config.typeFlag);
                config.table = res;
                if (config.table.Banners.length == 1)
                {
                   
                    //GenericService.getSortedStubs(res);
                    config.tableForExport = GenericService.clone(config.table);
                    if (this.indexDrill == 0)
                    {
                        config.type = "mscolumn2d";
                    }
                    else
                    {
                        config.type = "msline";
                    }
                    config["chartOptions"]["showLegend"] = 0;
                    var height = (config.table.Stubs.length * 18) < 300 ? 300 : (config.table.Stubs.length * 18);
                    config["height"] = height + 'px';

                }
                else if (config.table.Banners.length > 1)
                {
                    config.table.Banners[0].Color = "#BFBFBF";
                    config.tableForExport = GenericService.clone(config.table);
                    //config.table = DataService.transposeTable(config.table);
                    if (this.indexDrill == 0)
                    {
                        config.type = "msbar2d";
                    }
                    else
                    {
                        config.type = "msline";
                    }
                    config["chartOptions"]["showLegend"] = 1;
                    var height = (config.table.Stubs.length * 10) < 300 ? 300 : (config.table.Stubs.length * 10);
                    config["height"] = height + 'px';

                }
                FusionChartsService.renderChartFromTable(config);
                //var app = angular.module("myApp", ["ng-fusioncharts"]);
            },
                (err) =>
                {
                    console.log(err);
                });

        this.gs.ajaxSubscription.push(sub);
    }



    static colors = [
        '#589bD5',
        '#10426f',
        '#EEB500',
        '#007681',
        '#E87722',
        '#8064A2',
        '#C0504D',
        '#F79646',
        '#71B2C9',
    ]


    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }
}
