
import { GlobalService } from '../../providers/global.service';
import { MasterService } from '../../providers/master.service';
import { finalize } from 'rxjs/operators';
import { FusionChartsService } from 'app/providers/fusion-charts.service';
import { ChartService } from 'app/providers/chart.service';
import { BRANDS } from '../../../settings/brand';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from '../../providers/generic.service';
import { environment } from '../../../environments/environment';

@Component({
    moduleId: module.id,
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class PageLoginComponent
{
    model: any = {};
    loading = false;
    error = '';

    constructor(private router: Router, private authService: AuthService)
    {
    }

    login()
    {
        //this.router.navigate(['/awareness/0']);
        this.loading = true;
        this.error = null;
        this.authService.login(this.model.userName, this.model.password)
            .subscribe(
                (user) =>
                {
                    this.loading = false;
                    this.router.navigate(['/total/']);
                },
                (err) =>
                {
                    this.loading = false;
                    this.error = GenericService.fetchErrorMsg(err);
                    if (!this.error)
                        this.error = "Unable to connect to server.";
                }
            );
    }
}

