import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { environment } from '../../environments/environment';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable()
export class MasterService
{
    jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(private http: HttpClient)
    {

    }

    setUser(data): any
    {
        let user = this.jwtHelper.decodeToken(data);
        user.access_token = data;
        StorageService.set('user', user);
        return user;
    }

    callService1(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        let headers;

        //As per documentation of angular-jwt, access_token should have been appended automatically. But not working that why we added it manually
        //Will check it later on. And also need to see how refresh_tokens will work
        if (!skipAuth && !environment["skipAuth"])
        {
            var user = StorageService.get('user');
            headers = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                });

            if (user)
                headers = headers.set("Authorization", "Bearer " + user.access_token);
            headers = { headers: headers };
        }
        if (method == 'Get')
        {
            return this.http.get(environment.baseUrl + serviceUrl, headers).pipe(
                map((response) =>
                {
                    return response;
                }),
                catchError(this.handleError)
            );
        }
        else
        {
            return this.http.post(environment.baseUrl + serviceUrl, data, headers).pipe(
                map((response) =>
                {
                    return response;
                }),
                catchError(this.handleError)
            );
        }
    }

    callService(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        skipAuth = skipAuth || environment["skipAuth"];
        return this.callService1(serviceUrl, skipAuth, method, data);
    }

    callGetService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Get');
    }

    getList(serviceName: string = null, pageNo: number = null, pageSize: number = null, orderBy: string = null, filter: string = null, excel: boolean = false): Observable<any>
    {
        pageNo = pageNo || 1;
        pageSize = pageSize || 50;
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        var url = serviceName;
        if (url.indexOf('/') == -1)
            url += '/list';

        url = GenericService.addToQueryString(url, "pageNo", pageNo);
        url = GenericService.addToQueryString(url, "pageSize", pageSize);
        url = GenericService.addToQueryString(url, "orderBy", orderBy);
        url = GenericService.addToQueryString(url, "filter", filter);
        return this.callGetService(url);
    }

    getDetails(serviceName: string, recordId: any, key: string): Observable<any>
    {
        key = key || 'id';
        return this.callGetService(serviceName + '/details?' + key + '=' + recordId);
    }

    fetchData(serviceName: string, filter: string = null): Observable<any>
    {
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        return this.callGetService(serviceName + "?filter=" + filter);
    }

    bindDropDown(masterName, filterStr = null, locationID = 0, pageNo = 0, pageSize = 0)
    {
        filterStr = filterStr ? "Name:" + filterStr : '';
        var serviceName = masterName;
        return this.getList(serviceName, 0, 0, null, filterStr);
    }

    postData(serviceUrl: string, data: any, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    save(serviceUrl: string, data: any): Observable<any>
    {
        return this.postData(serviceUrl + '/save', data);
    }

    public handleError(error: HttpResponse<any> | any)
    {
        var errMsg = fetchErrorMessage(error);
        console.log(errMsg);
        return observableThrowError(errMsg);
    }

    public fetchErrorMessage(error: HttpResponse<any> | any)
    {
        return fetchErrorMessage(error);
    }
}

function fetchErrorMessage(error: HttpResponse<any> | any)
{
    let errMsg: string;
    if (error instanceof HttpResponse)
    {
        if (error.status == 0)
        {
            errMsg = "Could not connect to server";
        }
    }
    else if (typeof error === 'string')
    {
        errMsg = error;
    }
    else
    {
        error = error.error_description || error.error || error;
        error = error.error_description || error.error || error; //for error.error.error
        if (error instanceof ProgressEvent)
            errMsg = 'Unable to connect to server';
        else if (typeof error === 'string')
            errMsg = error;
        else
            errMsg = error.message || error.Message || error.body || error.toString();
    }
    return errMsg;
}
