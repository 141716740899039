import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from 'app/providers/generic.service';
import { MENU } from 'settings/menu';
import { GlobalService } from 'app/providers/global.service';
import { MasterService } from 'app/providers/master.service';
import { environment } from 'environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'vertical-navbar',
    templateUrl: 'vertical-navbar.component.html',
    styleUrls: ['vertical-navbar.component.scss'],
    host: { 
        'class': 'vertical-navbar',
        '[class.open]': 'open'
    }
})
export class VerticalNavbarComponent
{
    open: boolean;
    path;
    menuItems;
    subscription;
    gs = GlobalService;
    loading;
    ajaxSubscription: any;
    

    constructor(private router: Router, private masterService: MasterService)
    {
        this.open = false;
    }

    ngOnInit()
    {
        this.filterMenuItems();
    }

    filterMenuItems()
    {
        let items = GenericService.clone(MENU);
        this.menuItems = items.filter(x => !x.pages || x.pages.indexOf(GlobalService.path) > -1);

        this.menuItems.forEach(x =>
        {
            if (x.sub)
                x.sub = x.sub.filter(y => !y.pages || y.pages.indexOf(GlobalService.path) > -1);
        });
    }

    ope(path)
    {
        if (path)
            this.path = null;
        else
            path = this.path;

        this.router.navigate([path]);
    }

    exportToPPT()
    {
        let arr=this.gs.path.split('/');
        let page = arr[arr.length - 1];

        if (this.ajaxSubscription)
            this.ajaxSubscription.unsubscribe();
        var url = "ppt/create?page=" + page + "&filters=" + this.gs.serializeFilters();
        this.loading = true;
        this.ajaxSubscription = this.masterService.callGetService(url).pipe(
            finalize(() =>
            {
                this.loading = false;
            }))
            .subscribe(fileName =>
            {
                location.href = environment.baseUrl + "download-ppt?fileName=" + fileName;
            },
                (err) =>
                {
                    this.loading = false;
                    console.log(err);
                });
    }

    filterChange(filter)
    {
        if (filter.Change && GlobalService[filter.Change])
            GlobalService[filter.Change](this.masterService);
    }

    applyFilters()
    {
        GlobalService.emitFilterChanges();
        this.open = false;
    }

    resetFilters()
    {
        GlobalService.resetFilters();
        this.open = false;
    }

    onClickedOutside(navBar, event)
    {
        let clickedByFilterButton = event.target.name == 'filter-button' || (event.target.parentElement && event.target.parentElement.name == 'filter-button');
        if (clickedByFilterButton)
            this.open = true;
        else
        {
            if (!event.target.classList.contains('ng-option'))
                this.open = false;
        }
    }
}
