﻿export const BRANDS: any = [
    {
        "Code": 1,
        "Label": "Pepsi-Cola Regular",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true,
        "Default": false
    },
    {
        "Code": 2,
        "Label": "Coca-Cola Regular",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true,
        "Default": true
    },
    {
        "Code": 3,
        "Label": "Mountain Dew",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true,
        "Default": true
    },
    {
        "Code": 4,
        "Label": "7 Up",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true
    },
    {
        "Code": 5,
        "Label": "Mirinda",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true,
        "Default": true
    },
    {
        "Code": 6,
        "Label": "Fanta",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true
    },
    {
        "Code": 7,
        "Label": "Sprite",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true
    },
    {
        "Code": 8,
        "Label": "Vimto",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 9,
        "Label": "Shani",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 10,
        "Label": "Schweppes C+",
        "Category": "Reg CSP & Diet/Light CSPs",
        "Core": true
    },
    {
        "Code": 11,
        "Label": "Coca-Cola Light",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 12,
        "Label": "Coca-Cola Zero",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 13,
        "Label": "Diet Pepsi",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 14,
        "Label": "Pepsi Max",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 15,
        "Label": "B Cola",
        "Category": "Reg CSP & Diet/Light CSPs"
    },
    {
        "Code": 21,
        "Label": "Al Marai Juice",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 22,
        "Label": "Al Rabie",
        "Category": "Juice",
        "Core": true,
        "Default": true
    }
    ,
    {
        "Code": 23,
        "Label": "Al Safi Danao",
        "Category": "Juice",
        "Core": true        
    },
    {
        "Code": 24,
        "Label": "Alsafi",
        "Category": "Juice",
        "Core": true
        
    },
    {
        "Code": 25,
        "Label": "Caesar",
        "Category": "Juice",
        "Core": true        
    }
    ,
    {
        "Code": 26,
        "Label": "Nada",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 27,
        "Label": "Nadec",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 108,
        "Label": "Original",
        "Category": "Juice",
        
    },
    {
        "Code": 29,
        "Label": "Original Juice drink",
        "Category": "Juice"
        
    },
    {
        "Code": 30,
        "Label": "Rani Float",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 31,
        "Label": "Rani Juice",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 32,
        "Label": "Sun Cola",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 33,
        "Label": "Sun Top",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 34,
        "Label": "Vimto Juice",
        "Category": "Juice",
        "Core": true,
        "Default": true
    },
    {
        "Code": 35,
        "Label": "Sun Top Plus",
        "Category": "Juice",
        "Core": true,
        
    }
    ,
    {
        "Code": 36,
        "Label": "Tropicana",
        "Category": "Juice",
        "Core": true,
        
    },
    {
        "Code": 37,
        "Label": "Rauch",
        "Category": "Juice",
        "Core": true,
        
    },
    {
        "Code": 38,
        "Label": "Florida",
        "Category": "Juice",
        "Core": true,
        
    },
    {
        "Code": 39,
        "Label": "KDD",
        "Category": "Juice",
        "Core": true,
        
    },
    {
        "Code": 41,
        "Label": "Lipton",
        "Category": "RTD Tea",
        "Core": true
    },
    {
        "Code": 42,
        "Label": "Rauch",
        "Category": "RTD Tea",
        "Core": true
    },
    {
        "Code": 43,
        "Label": "Sun T",
        "Category": "RTD Tea"
    },
    {
        "Code": 44,
        "Label": "Snapple",
        "Category": "RTD Tea"
    },
    {
        "Code": 45,
        "Label": "Code Red",
        "Category": "Energy Drink",
        "Core": true
    },
    {
        "Code": 46,
        "Label": "Bison",
        "Category": "Energy Drink",
        "Core": true
    },
    {
        "Code": 47,
        "Label": "Red Bull",
        "Category": "Energy Drink",
        "Core": true
    },
    {
        "Code": 48,
        "Label": "Power Horse",
        "Category": "Energy Drink"
    },
    {
        "Code": 51,
        "Label": "Gatorade",
        "Category": "Sport Drink",
        "Core": true
    },
    {
        "Code": 52,
        "Label": "Powerade",
        "Category": "Sport Drink"
    },
    {
        "Code": 53,
        "Label": "Lucozade",
        "Category": "Sport Drink"
    },
    {
        "Code": 55,
        "Label": "Safa",
        "Category": "Bottled Water",
        "Core": true
    },
    {
        "Code": 56,
        "Label": "Nova",
        "Category": "Bottled Water",
        "Core": true
    },
    {
        "Code": 57,
        "Label": "Hana",
        "Category": "Bottled Water",
        "Core": true
    },
    {
        "Code": 58,
        "Label": "Aquafina",
        "Category": "Bottled Water",
        "Core": true
    },
    {
        "Code": 59,
        "Label": "Berain",
        "Category": "Bottled Water"
    },
    {
        "Code": 60,
        "Label": "Nestl� Pure Life",
        "Category": "Bottled Water"
    },
    {
        "Code": 61,
        "Label": "Barbican",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 62,
        "Label": "Holsten",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 63,
        "Label": "Moussy",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 64,
        "Label": "Bario",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 65,
        "Label": "Budweiser",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 66,
        "Label": "Efes",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 67,
        "Label": "Fayrouz",
        "Category": "Malts",
        "Core": true,
        "Default": true
    },
    {
        "Code": 71,
        "Label": "Vimto",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks",
        "Core": true,
        "Default": true
    },
    {
        "Code": 72,
        "Label": "Sunquick",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks",
        "Core": true
    },
    {
        "Code": 73,
        "Label": "Tang",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks",
        "Core": true,
        "Default": true
    },
    {
        "Code": 74,
        "Label": "Tono",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks",
        "Core": true
    },
    {
        "Code": 75,
        "Label": "Foster Clarks",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks"
    },
    {
        "Code": 76,
        "Label": "Roohafza",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks"
    },
    {
        "Code": 77,
        "Label": "Toot",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks"
    },
    {
        "Code": 78,
        "Label": "Towt",
        "Category": "Squashes/Cordials/Syrups/Powdered Soft Drinks"
    },
    {
        "Code": 81,
        "Label": "Nescafe",
        "Category": "RTD Coffee"
    },
    {
        "Code": 83,
        "Label": "Starbucks",
        "Category": "RTD Coffee"
    },
    {
        "Code": 84,
        "Label": "Luna",
        "Category": "RTD Coffee"
    },
    {
        "Code": 85,
        "Label": "Perrier",
        "Category": "Carbonated Bottled Water"
    },
    {
        "Code": 86,
        "Label": "S Pellegrino",
        "Category": "Carbonated Bottled Water"
    },
    {
        "Code": 87,
        "Label": "Perrier",
        "Category": "Flavoured Bottled Water"
    },
    {
        "Code": 88,
        "Label": "Schweppes",
        "Category": "Tonic Water"
    },
    {
        "Code": 89,
        "Label": "Fever Tree - Tonic",
        "Category": "Tonic Water"
    },
    {
        "Code": 90,
        "Label": "Almarai Flavoured Milk",
        "Category": "Flavoured Milk"
    },
    {
        "Code": 91,
        "Label": "Safio Flavoured Milk",
        "Category": "Flavoured Milk"
    },
    {
        "Code": 92,
        "Label": "Al Rabie Flavoured Milk",
        "Category": "Flavoured Milk"
    },
    {
        "Code": 93,
        "Label": "Galaxy/Dove Flavoured Milk",
        "Category": "Flavoured Milk"
    },
    {
        "Code": 94,
        "Label": "Activia",
        "Category": "Drinkable Yogurt"
    },
    {
        "Code": 95,
        "Label": "Almarai",
        "Category": "Drinkable Yogurt"
    },
    {
        "Code": 96,
        "Label": "Laban up",
        "Category": "Laban"
    }
]