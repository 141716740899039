﻿import { Component, OnInit, Input } from '@angular/core';
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
  selector: 'ni-file',
  templateUrl: './ni-file.component.html',
  styleUrls: ['./ni-file.component.scss']
})
export class NiFileComponent implements OnInit {
  @Input() title:string = '';
  @Input() type:string = '';
  @Input() image:string = '';
  @Input() size:string = 'normal';
  @Input() delete:boolean = false;
  @Input() spinner:boolean = false;
  @Input() link: any = false;
  imageDeleted: any = false;
  @Output() OnDelete = new EventEmitter(); 
    
  clickDelete()
  {
      this.imageDeleted = true;
      this.OnDelete.emit(this.imageDeleted);
  }

  constructor() {}

  ngOnInit() {}
}
