import { BrowserModule }                    from '@angular/platform-browser';
import { RouterModule }                     from '@angular/router';
import { NgModule }                         from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';

import { routes, AppRoutingModule }         from './app-routing.module';
import { AppComponent }                     from './app.component';
import { UIModule }                         from './ui/ui.module';
import { NiComponentsModule }               from './ni-components/ni-components.module';
import { PagesModule }                      from './pages/pages.module';

import { DefaultLayoutComponent }           from './layouts/default/default.component';
import { ExtraLayoutComponent }             from './layouts/extra/extra.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { AuthService } from './providers/auth.service';
import { AuthGuardService } from './providers/auth-guard.service';

import { GenericService } from './providers/generic.service';
import { StorageService } from './providers/storage.service';
import { MasterService } from './providers/master.service';
import { ChartService } from './providers/chart.service';
import { FusionChartsService } from './providers/fusion-charts.service';
import { GlobalService } from './providers/global.service';
import { DataService } from './providers/data.service';

export function getJwtHttp()
{
    return {
        tokenName: 'access_token',
        tokenGetter: (() =>
        {
            var user = StorageService.get('user');
            if (user)
                return user.access_token;
        }),
        globalHeaders: [{ 'Content-Type': 'application/json' }],
    };
}

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        ExtraLayoutComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        HttpClientModule,
        JwtModule.forRoot(
            {
                jwtOptionsProvider:
                {
                    provide: JWT_OPTIONS,
                    useFactory: getJwtHttp,
                }
            }),
        AppRoutingModule,
        UIModule,
        NiComponentsModule,
        PagesModule
    ],
    providers: [
        StorageService,
        GenericService,
        AuthService,
        AuthGuardService,
        MasterService,
        ChartService,
        FusionChartsService,
        GlobalService,
        DataService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
