import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { FormatPipe } from './format.pipe';
import { EqualPipe } from './equal.pipe';
import { KeysPipe } from './keys.pipe';

@NgModule({
    imports: [],
  declarations: [FilterPipe, YesNoPipe, FormatPipe, EqualPipe, KeysPipe],
  exports: [FilterPipe, YesNoPipe, FormatPipe, EqualPipe, KeysPipe],
    providers: [EqualPipe] 
})

export class PipesModule {}
