import { Component, OnInit, Input, Output } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { GenericService } from '../../providers/generic.service';
import { ChartService } from '../../providers/chart.service';
import { EventEmitter } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'chart',
    templateUrl: 'chart.component.html',
    styleUrls: ['chart.component.scss'],
})
export class ChartComponent
{
  @Input() config: any;
    @Input() library: any;
    @Output() chartToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    chartVisible: boolean;

    constructor(private chartService: ChartService)
    {
        this.chartVisible = true;
    }

    ngOnChanges()
    {
        //this.config.tableForExport = this.config.tableForExport || this.config.table;
    }

    getValue(dp): any
    {
        let decimals = this.config.fc.chart.decimals;
        return dp.Perc;
        //if (!this.config.fc.chart.showPercentValues)
        //    return GenericService.round(dp.Freq, decimals);
        //else
        //   // return GenericService.round(dp.Perc, decimals) + '%';
        //return GenericService.round(dp.Perc, decimals) ;

    }

    toggleChartToTable()
    {
        this.chartVisible = !this.chartVisible;
        this.config.tableForExport = this.config.tableForExport || this.config.table;
        this.config.mode = this.config.mode == 'table' ? 'chart' : 'table';
        this.chartToggleChange.emit(this.chartVisible);
    }

    exportToCsv()
    {
        this.config.tableForExport = this.config.tableForExport || this.config.table;
        let data = this.chartService.tableToDataTable(this.config.tableForExport);
        this.chartService.exportToCsv(data);
    }
}
