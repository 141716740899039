import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { ChartService } from './chart.service';
import { Subject } from 'rxjs';

declare var FusionCharts: any;

@Injectable()
export class FusionChartsService
{
    static defaultOptions =
        {
            labelFontColor: "#000",
            baseFontColor: "#fff",
            legendItemFontColor: "#000",
            legendItemFontSize: "12",
            pieRadius: "90",
            theme: "fint",
            showLabels: "1",
            showYAxisValues: "1",
            legendPosition: "bottom",
            legendAllowDrag: "0",
            showBorder: "0",
            showLegend: "1",
            showValues: "1",
            bgAlpha: "0",
            canvasBgAlpha: "0",
            toolTipBgColor: "#000000",
            rotateValues: "0",
            decimals: 0,
            showPercentValues: "1",
            labelFontBold: "0",
            numberSuffix: '%',
            labelFontSize: "12",
            valueFontSize: "12",
            enableSmartLabels: "1",
            yAxisMaxValue: "120",
            valueBgHoverAlpha: "5",
            placeValuesInside: "0",
            valueFontColor: "#000",
            numDivLines: 0,
            divLineAlpha: 3,//To remove top div line,
        };

    private static _clickObserver = new Subject();
    static clickObservable = FusionChartsService._clickObserver.asObservable();

    static setHeightWidth(config)
    {
        config.width = config.width ? config.width : "100%";
        config.height = config.height ? config.height : "300px";
        if (config.height.indexOf('vh') > -1)
        {
            let height = config.height.replace('vh', '');
            config.height = (window.innerHeight * height / 100) + 'px';
        }
    }

    static renderChartSS(config: any)
    {
        let fc: any = {};
        fc.chart = GenericService.clone(this.defaultOptions);
        if (config.chartOptions)
            fc.chart = Object.assign(fc.chart, config.chartOptions);

        fc.data = config.data;
        this.setHeightWidth(config);
        return this.renderChartById(config, fc);
    }

    static renderChartFromTable(config: any)
    {
        config.table = config.table || { Banners: [], Stubs: [] };
        let fc: any = { categories: [{ category: [] }], dataSet: [] };
        fc.chart = GenericService.clone(this.defaultOptions);

        if (config.chartOptions)
            fc.chart = Object.assign(fc.chart, config.chartOptions);

        if (config.annotations)
            fc.annotations = config.annotations;

        config.table.Stubs.forEach(x =>
        {
            fc.categories[0].category.push({ "label": x.Name, "color": x.Color });
        });

        if (config.table.Banners.length > 1)
        {
            for (let i = 0; i < config.table.Banners[0].Values.length; i++)
            {
                var value1 = config.table.Banners[0].Values[i].Perc;
                var value2 = config.table.Banners[1].Values[i].Perc;
                if (value1 > value2)
                {
                    //config.table.Banners[1].Values[i].Color = "#ff0000";//green
                    config.table.Banners[1].Values[i].AnchorImageUrl = "assets/img/down.png";
                }
                //else if (value1 < value2)
                //{
                //    config.table.Banners[0].Values[i].Color = "#ff0000";//red
                //    config.table.Banners[1].Values[i].Color = "#00ff00";//green
                //}
            }
        }

        config.table.Banners.forEach((banner, k) =>
        {
            let color = banner.Color || ChartService.getColor(k);

            let ds: any = {
                seriesName: banner.Name,
                color: color,
                data: banner.Values.map((x, i) =>
                {
                    let toolText = "";
                    if (x.Series || banner.Name)
                        toolText += "Series: " + (x.Series || banner.Name) + "{br}";
                    if (x.Category || (config.table.Stubs[i] && config.table.Stubs[i].Name))
                        toolText += "Data Point: " + (x.Category || config.table.Stubs[i].Name) + "{br}";

                    if (x.Perc)
                    {
                        if (fc.chart.numberSuffix == '%')
                            toolText += "Percentage: " + GenericService.round(x.Perc, 2) + "% {br}";
                        else
                            toolText += "Avg: " + GenericService.round(x.Perc, 2) + " {br}";
                    }
                    if (x.Freq)
                        toolText += "Frequency: " + GenericService.round(x.Freq, 2) + "{br}";

                    if (x.Base)
                        toolText += "Base: " + GenericService.round(x.Base, 2) + "{br}";

                    if (x.Base_Unweighted)
                        toolText += "Base(Unweighted): " + GenericService.round(x.Base_Unweighted, 2) + "{br}";

                    if (x.Min)
                        toolText += "Min: " + GenericService.round(x.Min, 2) + "{br}";

                    if (x.Max)
                        toolText += "Max: " + GenericService.round(x.Max, 2) + "{br}";

                    if (x.Avg)
                        toolText += "Average: " + GenericService.round(x.Avg, 2) + "{br}";

                    let obj: any = {
                        value: x.Perc || x.Freq,
                        toolText: toolText
                    };

                    if (x.AnchorImageUrl)
                        obj.AnchorImageUrl = "assets/img/down.png";

                    if (x.ToolTip)
                        obj.tooltext = x.ToolTip;

                    if (k == 0)
                        obj.valuePosition = 'below';
                    else
                        obj.valuePosition = 'above';

                    let displayvalue: any = GenericService.round(x.Perc, 0);

                    if (x.Perc < 1 && config.type != "pie2d")
                    {
                        //displayvalue = ' ';
                        displayvalue = GenericService.round(x.Perc, 1) + "%";
                        obj.displayvalue = displayvalue
                    };
                    return obj;
                }),
            };
            for (let i = 0; i < fc.categories[0].category.length; i++)
            {
                if (config.type == "pie2d" || config.type == "doughnut2d")
                    ds.data[i].label = fc.categories[0].category[i].label;

                if (ds.data[i])
                    ds.data[i].color = fc.categories[0].category[i].color || ds.data[i].color;
                if (config.type == "pie2d" || config.type == "doughnut2d")
                {
                    ds.data[i].color = ds.data[i].color || ChartService.getColor(i);
                }
            }

            fc.dataSet.push(ds);
            if (config.showTrendLines)
            {
                fc.trendlines = [{
                    line: [{
                        "startvalue": "67",
                        "color": "#BFBFBF",
                        "valueOnRight": "1",
                        "displayvalue": config.trendLineDisplayValue,
                        "dashed": "1"
                    }]
                }]
            }
        });

        this.setHeightWidth(config);
        config.fc = fc;
        let chart = this.renderChartById(config, fc);
        return chart;
    }

    static renderChartById(config, dataSource)
    {
        return FusionCharts.ready(function ()
        {
            if (document.getElementById(config.id))
            {
                let chart = new FusionCharts({
                    type: config.type,
                    renderAt: config.id,
                    width: config.width || "100%",
                    height: config.height || "250px",
                    containerBackgroundOpacity: 0,
                    dataFormat: 'json',
                    dataSource: dataSource,
                    'events': {
                        'dataplotClick': function (eventObj, dataObj)
                        {
                            let obj: any = {};
                            obj.Index = dataObj.dataIndex;
                            obj.data = dataObj.datasetName;
                            //obj.mallid = eventObj.sender.args.dataSource.categories[0].category[dataObj.dataIndex].label;
                            if (dataObj.datasetName == "Malls")
                            {
                                obj.chartType = "Mall";
                                obj.data = eventObj.sender.args.dataSource.categories[0].category[dataObj.dataIndex].label;
                            }
                            else if (dataObj.datasetName == "Performance")
                            {
                                obj.chartType = "Overall";
                                obj.data = dataObj.dataIndex;
                                obj.title = dataObj.categoryLabel;
                            }
                            if (dataObj.datasetName == "Category")
                            {
                                obj.chartType = "Category";
                                obj.data = eventObj.sender.args.dataSource.categories[0].category[dataObj.dataIndex].label;
                            }
                            //else if (dataObj.datasetName == "banner2")
                            //{
                            //    obj.chartType = "Drill";
                            //    obj.data = dataObj.dataIndex;
                            //}
                            //obj.bannerId = eventObj.sender.args.dataSource.dataSet[dataObj.datasetIndex].id;
                            if (dataObj.datasetName == "Malls" || dataObj.datasetName == "Performance" || dataObj.datasetName == "Category")
                            {
                                FusionChartsService._clickObserver.next(obj);
                            }
                            //else if (dataObj.datasetName == "banner1")
                            //{
                            //    FusionChartsService._clickObserver.next(obj);
                            //}
                        }
                    }
                });

                chart.render();
            }
        });
    }
}
