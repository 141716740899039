import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from "app/providers/auth.service";
import { Router, ActivatedRoute, Route } from '@angular/router';
import { PageCategoryComponent } from '../../../pages/Category/category.component';
import { GlobalService } from '../../../providers/global.service';

@Component({
  moduleId: module.id,
  selector: 'logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  host: { 'class': 'app-logo' }
})
export class LogoComponent implements OnInit
{
    
    baseUrl;
  constructor(private router: Router, private auth: AuthService)
  {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() { }

  //clickHome()
  //{     
  //    this.router.navigate(['/home/nakheel-malls']);
  //  }

    home()
    {
        window.location.reload();
       
    }

}
