import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { finalize } from "rxjs/internal/operators/finalize";
import { Observable } from "rxjs";
import { BRANDS } from 'settings/brand';

@Injectable()
export class GenericService
{

    static getFileExtension(fileName)
    {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)
    }

    public static serializeDate(date): string
    {
        return 's';
    }
    public static nullToEmpty(value): string
    {
        value = value ? value : '';
        return value;
    }

    public static isEmpty(value: any): boolean
    {
        // we don't check for string here so it also works with arrays
        return value == null || value.length === 0;
    }

    public static fetchErrorMsg(error)
    {
        if (error == null)
            return "";

        if (typeof error === 'object')
        {
            try
            {
                error = error.json();
            }
            catch (err)
            {
            }

            if (error.message)
                return error.message;

            if (error instanceof ProgressEvent)
            {
                return "Could not connect to server.";
            }

            error = error.error_description || error.Message || error._body || error;

            return JSON.stringify(error);
        }
        return error;
    }

    public static datePickerOptions =
        {
            singleDatePicker: true,
            showDropdowns: true,
            opens: "left",
        }

    public static getPropValue(obj, propPath)
    {
        var arr = propPath.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    public static setPropValue(object, path, value)
    {
        var a = path.split('.');
        var o = object;
        for (var i = 0; i < a.length - 1; i++)
        {
            var n = a[i];
            if (n in o)
            {
                o = o[n];
            } else
            {
                o[n] = {};
                o = o[n];
            }
        }
        o[a[a.length - 1]] = value;
    }

    public static toCsv(list, propName = null, seperator = ",")
    {
        if (!list)
            return "";
        var str = "";
        for (let item of list)
        {
            str += seperator + "" + (propName ? item[propName] : item);
        }
        if (str.length > 0)
            return str.substring(1, str.length);

        return str;
    }

    public static getRandomNumber(min, max) //less than max, greater than or equal to min
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    public static getRandomColorHex()
    {
        return '#' + Math.random().toString(16).slice(-6);
    }

    public static clone(obj)
    {
        return JSON.parse(JSON.stringify(obj));
    }

    public static getCurrentPath()
    {
        return location.href.split("#")[1];
    }
    Zc
    public static addFilter(url, filter)
    {
        if (filter)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            if (url.indexOf('filters=') == -1)
                qs += "filters=";
            else
                qs += ";";
            qs += filter;
            return url + qs;
        }
        return url;
    }

    public static addToQueryString(url, key, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            qs += key + "=" + value;
            return url + qs ;
        }
        return url;
    }

    public static addToQuery(url, key, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            qs += key + "=" + value;
            return url + qs + "perc-multi-resp-table? json = Overall.json";
        }
        return url;
    }

    public static addToString(url, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            qs  + "=" + value;
            return url + qs;
        }
        return url;
    }

    public static csvToJson(csv)
    {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++)
        {

            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++)
            {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        return result;
    }

    public static round(value, upTo = 2)
    {
        let mul = 1;
        for (let i = 0; i < upTo; i++)
            mul *= 10;
        return (Math.round(value * mul) / mul);
    }

    static escapeRegExp(string)
    {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static replaceAll(text, searchText, replaceWith)
    {
        replaceWith = replaceWith || '';
        if (text && searchText)
            return text.replace(new RegExp(GenericService.escapeRegExp(searchText), "g"), replaceWith);

        else
            return text;
    }

    static padLeft(str, targetLength, padWith)
    {
        str = String(str);
        targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
        padWith = String(typeof padWith !== 'undefined' ? padWith : ' ');
        if (str.length >= targetLength)
        {
            return str
        } else
        {
            targetLength = targetLength - str.length;
            if (targetLength > padWith.length)
            {
                padWith += padWith.repeat(targetLength / padWith.length); //append to original to ensure we are longer than needed
            }
            return padWith.slice(0, targetLength) + str;
        }
    }

    static toTitleCase(str)
    {
        if (str == "" || str == null)
            return "";

        str = str.trim();
        return str.toLowerCase().split(' ').map(function (word)
        {
            if (word.length == 0)
                return "";
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }

    static countBase(url, masterService): Observable<any>
    {
        return masterService.callGetService(url)
            .map(count =>
            {
                return count;
            },
            (err) =>
            {
                console.log(err);
            });
    }

    static sum(arr, prop)
    {
        let total = 0;
        arr.forEach(x =>
        {
            total += prop ? x[prop] : x;
        });

        return total;
    }

    static removeDuplicates(arr: any[], prop = null)
    {
        const filteredArr = arr.reduce((acc, current) =>
        {
            const x = acc.find(item => prop ? item[prop] === current[prop] : item === current);
            if (!x)
            {
                return acc.concat([current]);
            } else
            {
                return acc;
            }
        }, []);

        return filteredArr;
    }

    static getSortedStubs(table, bannerIndex = 0, desc = true)
    {
        let stubsNew = [];
        let bannersNew = [];
        let max = table.Banners[bannerIndex].Values[0];
        let arrayOfBannerValues = table.Banners[bannerIndex].Values.map(a => a.Perc)
        let n = table.Banners[bannerIndex].Values.length;
        var sortedBannerValues = arrayOfBannerValues.sort((n1, n2) => n1 - n2);
        if (desc)
        {
            sortedBannerValues.reverse();
        }
        let sortedCopyArray = [];
        for (let i = 0; i < n; i++)
        {
            sortedCopyArray.push(sortedBannerValues[i]);
        }

        for (let j = 0; j < n; j++)
        {
            var newInd = sortedBannerValues.indexOf(table.Banners[bannerIndex].Values[j].Perc);
            table.Stubs[j]["newStubIndex"] = newInd;
            sortedBannerValues[newInd] = 999999;

        }
        for (let x = 0; x < table.Banners[bannerIndex].Values.length; x++)
        {
            table.Banners[bannerIndex].Values[x].Perc = sortedCopyArray[x];
        }
        for (let k = 0; k < table.Stubs.length; k++)
        {
            var tempStub = table.Stubs.find(x => x.newStubIndex == k);
            stubsNew[k] = tempStub;
        }

        delete table.Stubs;
        table.Stubs = stubsNew;
        return table;

    }

    static getTop10DataWithDefaultBrands(table, juiceMalt)
    {
        let tableClone = GenericService.clone(table);
        GenericService.getSortedStubs(table);        

        var brandCodes = BRANDS.filter(x => x.Category == juiceMalt || juiceMalt == "All").map(x => x.Code);
        if (juiceMalt != "All")
        {
            var deleteList = [];
            table.Stubs.forEach((x, i) =>
            {
                if (brandCodes.filter(y => y == x.Id).length == 0)
                    deleteList.push(i);
            });

            deleteList = deleteList.sort(function (a, b) { return b - a });

            deleteList.forEach(x =>
            {
                table.Stubs.splice(x, 1);
                table.Banners.forEach(y =>
                {
                    y.Values.splice(x, 1);
                });
            });
        }

        let defaultBrands = [{ Name: 'Rani Float', Value: 30 }, { Name: 'Rani Juice', Value: 31 }, { Name: 'Vimto Juice', Value: 34 }];
        if (juiceMalt == "Malts")
            defaultBrands = [{ Name: 'Barbican', Value: 61 }];
        else if (juiceMalt == "All")
            defaultBrands.push({ Name: 'Barbican', Value: 61 });

        var n = table.Stubs.length - 10;
        table.Stubs.splice(10, n);
        table.Banners.forEach(x =>
        {
            x.Values.splice(10, n);
        });

        defaultBrands.forEach(x =>
        {
            if (table.Stubs.filter(y => y.Name == x.Name || y.Name == x.Value).length == 0)
            {
                tableClone.Stubs.forEach((z, i) =>
                {
                    if (z.Name == x.Name || z.Name == x.Value)
                    {
                        var stub = GenericService.clone(tableClone.Stubs[i]);
                        table.Stubs.push(stub);
                        table.Banners.forEach((k, j) =>
                        {
                            var value = GenericService.clone(tableClone.Banners[j].Values[i]);
                            k.Values.push(value);
                        });
                    }
                });
            }
        });

        GenericService.getSortedStubs(table);   

    }
}
