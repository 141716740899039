import { Component } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { MasterService } from '../../providers/master.service';
import { finalize } from 'rxjs/operators';
import { FusionChartsService } from 'app/providers/fusion-charts.service';
import { ChartService } from 'app/providers/chart.service';
import { BRANDS } from '../../../settings/brand';

@Component({
    moduleId: module.id,
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class PageHomeComponent
{
    title: string;
    gs = GlobalService;
    loading = false;
    waveWiseCount: any = [];

    configs: any =
        [
            { id: 'base-across-waves', service: 'count-across?variable=F1_OE&crossByVar=Q_Wave' },
            { id: 'F1_OE', title: 'Favorite Beverage Brand', type: 'msline', service: 'perc-cross-tab-table?variable=F1_OE&crossByVar=Q_Wave', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
            { id: 'F2_OE', title: 'Favorite Fruit Juice Brand', type: 'msline', service: 'perc-cross-tab-table?variable=F2_OE&crossByVar=Q_Wave', chartOptions: { showLegend: 0 }, typeFlag: 'Juice' },
            { id: 'F3_OE', title: 'Favorite Malt Brand', type: 'msline', service: 'perc-cross-tab-table?variable=F3_OE&crossByVar=Q_Wave', chartOptions: { showLegend: 0 }, typeFlag: 'Malts' },
            { id: 'BA1_TOM', title: 'TOM Awareness', type: 'msline', service: 'perc-cross-tab-table?crossByVar=Q_Wave&ignoreNoResponse=false', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
            { id: 'Total_Spontaneous', title: 'Total Spontaneous Awareness', type: 'msline', service: 'perc-cross-tab-table?crossByVar=Q_Wave&ignoreNoResponse=false&removeNotFound=false', chartOptions: { showLegend: 0 }, typeFlag: 'All' },
            { id: 'Total_Awareness', title: 'Total Awareness', type: 'msline', service: 'perc-cross-tab-table?crossByVar=Q_Wave&ignoreNoResponse=false', chartOptions: { showLegend: 0 }, typeFlag: 'All' }
        ];

    constructor(private masterService: MasterService, private chartService: ChartService)
    {
        this.title = "Snapshot";
        GlobalService.changeTitle(this.title);
        GlobalService.routeChange();
    }

    async ngOnInit()
    {
        this.gs.subscription = GlobalService.filtersObservable.subscribe(filters =>
        {
            this.run();
        });
        if (GlobalService.filters && GlobalService.filters[0].List)
            this.run();
    }

    run()
    {
        this.loading = true;
        this.gs.unsubscribeAjax();
        let fltrObj = GlobalService.getMongoFiltersObj(null, ['Brand Type', 'Category']);

        let brandCodes = GlobalService.getFilterValue(fltrObj, 'Brand');
        brandCodes = Array.isArray(brandCodes) || !brandCodes ? brandCodes : [brandCodes];

        fltrObj = GlobalService.removeFromMongoFilters(fltrObj, 'Brand');

        let fltrObjClone;
        for (let config of this.configs)
        {
            config.avg = null;
            config.table = null;
            config.visible = true;
            fltrObjClone = GenericService.clone(fltrObj);

            let service = config.service;
            let url = config.service;
            if (config.id == "BA1_TOM")
            {
                let variables = "";
                if (brandCodes)
                {
                    brandCodes.forEach((x, i) =>
                    {
                        variables += (i > 0 ? "," : "") + "-" + x;
                    });
                }
                url = GenericService.addToQueryString(service, "variable", config.id);
                if (variables != "")
                    url = GenericService.addToQueryString(url, "excludedValues", variables);
            }
            else if (config.id == "Total_Spontaneous" || config.id == "Total_Awareness")
            {
                let variables = GlobalService.buildVariables(config.id, brandCodes);
                if (brandCodes)
                    url = GenericService.addToQueryString(service, "variables", GenericService.toCsv(variables));
                else
                    url = GenericService.addToQueryString(service, "variables", config.id + "_ERA***");
            }
            else if (config.id == "F1_OE" || config.id == "F2_OE" || config.id == "F3_OE")
            {
                if (brandCodes)
                {
                    let codes = brandCodes.map(x => "-" + x);
                    url = GenericService.addToQueryString(service, "excludedValues", GenericService.toCsv(codes));
                }
            }

            if (fltrObjClone)
            {
                var filters = JSON.stringify(fltrObjClone);
                url = GenericService.addToQueryString(url, "filters", filters);
            }

            config.loading = true;
            let sub = this.masterService.callGetService(url).pipe(
                finalize(() =>
                {
                    config.loading = false;
                    this.loading = false;
                }))
                .subscribe(res =>
                {

                    if (config.id != 'base-across-waves')
                    {
                        let resClone = GenericService.clone(res);
                        GenericService.getSortedStubs(res);
                        GenericService.getTop10DataWithDefaultBrands(res, config.typeFlag);
                        config.table = res;

                        if (config.table.Banners.length == 1)
                            config.type = "msbar2d";
                        FusionChartsService.renderChartFromTable(config);
                    }
                    else
                    {
                        this.waveWiseCount = Array.isArray(res) ? res : [res];
                        this.waveWiseCount.forEach(x =>
                        {
                            x.Color = GlobalService.keyColors[Object.keys(x)[0]];
                            x.Name = Object.keys(x)[0];
                            x.Value = x[x.Name];
                        });
                    }

                },
                    (err) =>
                    {
                        console.log(err);
                    });

            this.gs.ajaxSubscription.push(sub);
        }
    }

    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }
}
