export const MENU: any = [
    //{
    //    title: 'Store',
    //    icon: {
    //        class: 'fa fa-brain',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: 'store'
    //},
    //{
    //    title: 'Awareness',
    //    icon: {
    //        class: 'fa fa-brain',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    sub: [
    //        {
    //            title: 'Snapshot',
    //            routing: '/awareness/0'
    //        },
    //        {
    //            title: 'Trend',
    //            routing: '/awareness/1'
    //        }
    //    ]
    //}
    //{
    //    title: 'Total',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/awareness/0'
    //},
    //{
    //    title: 'Availabilty',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    sub: [
    //        {
    //            title: 'Cooler',
    //            routing: '/cooler/'
    //        },
    //        {
    //            title: 'Coke',
    //            routing: '/coke/'
    //        },
    //        {
    //            title: 'Fanta',
    //            routing: '/fanta/'
    //        },
    //        {
    //            title: 'Sprite',
    //            routing: '/sprite/'
    //        },
    //        {
    //            title: 'Kinley',
    //            routing: '/kinley/'
    //        },
    //        {
    //            title: 'Activation',
    //            routing: '/activation/'
    //        }
    //   ]
    //},
    {
        title: 'Mall',
        icon: {
            class: 'fas fa-shopping-bag',
            bg: 'blue',
            color: '#fff'
        },
        routing: '/total'
    },
    {
        title: 'Category',
        icon: {
            class: 'fas fa-shopping-bag',
            bg: 'blue',
            color: '#fff'
        },
        routing: '/category'
    },
    //{
    //    title: 'overall',
    //    icon: {
    //        class: 'fas fa-shopping-bag',
    //        bg: 'blue',
    //        color: '#fff'
    //    },
    //    routing: '/category'
    //},
    //{
    //    title: 'Overall',
    //    icon: {
    //        class: 'fas fa-shopping-bag',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/overall'
    //},
    //{
    //    title: 'Fanta',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/fanta'
    //},
    //{
    //    title: 'Sprite',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/sprite'
    //},
    //{
    //    title: 'Kinley',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/kinley'
    //},
    //{
    //    title: 'Activation',
    //    icon: {
    //        class: 'fas fa-glass-cheers',
    //        bg: 'rgb(102, 104, 111)',
    //        color: '#fff'
    //    },
    //    routing: '/activation'
    //}
]