﻿import { Injectable } from '@angular/core';

@Injectable()
export class StorageService
{
    public static set(key,value): void
    {
        var valStr = JSON.stringify(value);
        localStorage.setItem(key, valStr);
    }

    public static get(key): any
    {
        var valStr = localStorage.getItem(key);
        var value = JSON.parse(valStr);
        return value;
    }

    public static remove(key): void
    {
        localStorage.removeItem(key);
    }
}